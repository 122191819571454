import { graphql } from 'gatsby';
import * as React from 'react';

import { HeadMeta, Layout, WidgetTrustElements } from '../components';
import { FensterWidget, TurenWidget } from '../components/FensterTurenWidget';
import { LanguageContext } from '../context/LanguageContext';
import { IContentfulTrustBlock } from '../utils/fragments/trustBlockFragment';
import { formatInfoLinks, formatMenuLinks } from '../utils/helpers';
import {
  IAllContentfulCategory,
  IContentfulPage,
  IContentfulVacationBanner,
} from '../utils/types';

interface IFensterProps {
  data: {
    contentfulPage: IContentfulPage;
    contentfulTrustBlock: IContentfulTrustBlock;
    contentfulVacationBanner?: IContentfulVacationBanner;
    allContentfulCategory: IAllContentfulCategory;
    allContentfulPrivacyAndToS: {
      nodes: {
        title: string;
        url: string;
      }[];
    };
  };
}

const FensterTuren: React.FunctionComponent<IFensterProps> = ({ data }) => {
  const [product, setProduct] = React.useState<'windows' | 'doors'>();

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');
    setProduct(type === 'doors' ? 'doors' : 'windows');
  }, []);

  const menuLink = formatMenuLinks(data.allContentfulCategory.nodes, 'De');
  const infoLink = formatInfoLinks(data.allContentfulPrivacyAndToS.nodes, 'De');

  return (
    <LanguageContext.Provider value={'De'}>
      <Layout
        links={menuLink}
        infoLinks={infoLink}
        vacationBanner={data.contentfulVacationBanner}
        isWidget
      >
        <HeadMeta title={'Widget Abfrage'} />
        {product === 'windows' && <FensterWidget />}
        {product === 'doors' && <TurenWidget />}
        <WidgetTrustElements elements={data.contentfulTrustBlock.elements} />
      </Layout>
    </LanguageContext.Provider>
  );
};

export const query = graphql`
  {
    allContentfulCategory(
      sort: { fields: order }
      filter: { language: { eq: "De" } }
    ) {
      nodes {
        ...category
      }
    }
    contentfulTrustBlock {
      ...trustBlock
    }
    allContentfulPrivacyAndToS(filter: { language: { eq: "De" } }) {
      nodes {
        title
        language
        url: slug
      }
    }
    contentfulVacationBanner {
      ...vacationBanner
    }
  }
`;

export default FensterTuren;
